export default {
    "props": {
        "buyer": {
            "tabOfferOfferBtn": true,
            "showAddIcon": true,
            "showBookaViewingBtn": true,
            "showMakeAnOfferBtn": true
        },
        "tenant": {
            "tabOfferOfferBtn": true,
            "showAddIcon": true,
            "showBookaViewingBtn": true,
            "showMakeAnOfferBtn": true
        },
        "seller": {

        },
        "landlord": {
            "showPendingAction": true
        }
    },
    "sx": (theme) => ({
    })
}